"use client";

import Resources from "@/components/common/Resources";
import Container from "@/components/common/container";
import { MISSING_LABELS, ROUTE_LABELS } from "@/utils/common/translations";
import { Button } from "@lula-technologies-inc/lux";
import clsx from "clsx";
import Image from "next/image";
import { useRouter } from "next/navigation";
import React from "react";

const NotFoundPage: React.FC = () => {
  const router = useRouter();

  return (
    <Container title={ROUTE_LABELS.MISSING} subTitle={MISSING_LABELS.SUBTITLE}>
      <div className={clsx("my-12")}>
        <div
          className={clsx(
            "mx-auto",
            "text-center",
            "flex",
            "justify-center",
            "items-center"
          )}
        >
          <Image
            src="/icons/ConfusedHorse.png"
            width={375}
            height={496}
            alt="404 Image"
            className={clsx("block")}
          />
        </div>
        <div
          className={clsx(
            "mx-auto",
            "text-center",
            "flex",
            "justify-center",
            "items-center",
            "mt-6"
          )}
        >
          <Button
            type="primary"
            onClick={() => {
              router.push("/");
            }}
          >
            Go Home
          </Button>
        </div>
      </div>
      <Resources />
    </Container>
  );
};

export default NotFoundPage;
